// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cennik-kusnierstwo-js": () => import("./../../../src/pages/cennik/kusnierstwo.js" /* webpackChunkName: "component---src-pages-cennik-kusnierstwo-js" */),
  "component---src-pages-cennik-tapicerstwo-meblowe-js": () => import("./../../../src/pages/cennik/tapicerstwo-meblowe.js" /* webpackChunkName: "component---src-pages-cennik-tapicerstwo-meblowe-js" */),
  "component---src-pages-cennik-tapicerstwo-medyczne-js": () => import("./../../../src/pages/cennik/tapicerstwo-medyczne.js" /* webpackChunkName: "component---src-pages-cennik-tapicerstwo-medyczne-js" */),
  "component---src-pages-cennik-tapicerstwo-samochodowe-js": () => import("./../../../src/pages/cennik/tapicerstwo-samochodowe.js" /* webpackChunkName: "component---src-pages-cennik-tapicerstwo-samochodowe-js" */),
  "component---src-pages-galeria-kusnierstwo-js": () => import("./../../../src/pages/galeria/kusnierstwo.js" /* webpackChunkName: "component---src-pages-galeria-kusnierstwo-js" */),
  "component---src-pages-galeria-pozostale-js": () => import("./../../../src/pages/galeria/pozostale.js" /* webpackChunkName: "component---src-pages-galeria-pozostale-js" */),
  "component---src-pages-galeria-tapicerstwo-js": () => import("./../../../src/pages/galeria/tapicerstwo.js" /* webpackChunkName: "component---src-pages-galeria-tapicerstwo-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-o-firmie-js": () => import("./../../../src/pages/o-firmie.js" /* webpackChunkName: "component---src-pages-o-firmie-js" */),
  "component---src-pages-oferta-kaletnictwo-js": () => import("./../../../src/pages/oferta/kaletnictwo.js" /* webpackChunkName: "component---src-pages-oferta-kaletnictwo-js" */),
  "component---src-pages-oferta-krawiectwo-js": () => import("./../../../src/pages/oferta/krawiectwo.js" /* webpackChunkName: "component---src-pages-oferta-krawiectwo-js" */),
  "component---src-pages-oferta-kusnierstwo-js": () => import("./../../../src/pages/oferta/kusnierstwo.js" /* webpackChunkName: "component---src-pages-oferta-kusnierstwo-js" */),
  "component---src-pages-oferta-rymarstwo-js": () => import("./../../../src/pages/oferta/rymarstwo.js" /* webpackChunkName: "component---src-pages-oferta-rymarstwo-js" */),
  "component---src-pages-oferta-tapicerstwo-js": () => import("./../../../src/pages/oferta/tapicerstwo.js" /* webpackChunkName: "component---src-pages-oferta-tapicerstwo-js" */)
}

