import React, { Component } from 'react';
import { Link } from "gatsby"
import "../styles/navigation.scss"
import logo from "../images/main/logo.svg"
import logoScroll from "../images/main/logo_scroll.svg"
import topImg from "../images/main/top.svg"

class Navigation extends Component {
    state = {
        scrollPosition: 0,
        imgScroll: false,
        offer: true,
        showNav: true,
        width: 0,
        activeMobile: false

    }
    handleResize = () => {
        this.setState({
            width: window.innerWidth
        })
    }
    handleScroll = () => {
        let { scrollPosition } = this.state;
        let currentScrollY = window.scrollY;
        let width = this.state.width;

        if (width < 781) {
            return
        }

        if (currentScrollY > 80) {
            this.setState({ offer: false });
        } else {
            this.setState({ offer: true });
        }
        if (currentScrollY > scrollPosition) {
            this.setState({ showNav: false });
        } else {
            this.setState({ showNav: true });
        }

        this.setState({
            scrollPosition: window.scrollY
        })
    }
    changeImageOnScroll = () => {
        if (this.state.scrollPosition > 50) {
            setTimeout(() => {
                this.setState({ imgScroll: true });
            }, 100)

        } else {
            setTimeout(() => {
                this.setState({ imgScroll: false });
            }, 50)
        }
    }
    componentDidMount() {
        this.handleResize()
        this.changeImageOnScroll()
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('scroll', this.changeImageOnScroll);
        window.addEventListener('resize', this.handleResize);
    }
    componentDidUpdate() {
        if (this.state.activeMobile === true) {
            document.body.style.overflow = "hidden";
        } else document.body.style.overflow = "auto"
    }
    handleMobileMenu = (param) => {
        if (param === false) {

            this.setState({
                activeMobile: false
            })

        }
        else {
            this.setState({
                activeMobile: !this.state.activeMobile
            })
        }

    }

    setClassName = () => {
        let navClass = ["navigation"];
        if (this.state.scrollPosition > 50) {
            navClass.push("scroll")
        }
        if (this.props.location.pathname !== "/") {
            navClass.push("boxshadow")
        }
        if (this.state.showNav === false) {
            navClass.push("hide")
        }
        if (this.state.activeMobile) {
            navClass.push("showMobile")
        }
        return navClass
    }
    scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    render() {
        const regexOffer = /.*oferta/;
        const regexPrice = /.*cennik/;
        const regexGallery = /.*galeria/;
        const regexKus = /.*kusnierstwo/
        const regexTapMed = /.*medyczne/
        const regexTapSam = /.*samochodowe/
        const regexTapMeb = /.*meblowe/
        const regexTap = /.*tapicerstwo/
        const regexOther = /.*pozostale/
        const regexKraw = /.*krawiectwo/
        const regexRym = /.*rymarstwo/
        const regexKal = /.*kaletnictwo/

        return (
            <>
                <div onClick={this.handleMobileMenu} className="menu-link-wrapper">
                    <div className={this.state.activeMobile ? "menu-link menu-trigger-open" : "menu-link"}>
                        <span className="lines"></span>
                    </div>
                </div>
                {this.state.scrollPosition > 200 ?
                    <div className="backToTop" onClick={this.scrollToTop}>
                        <img src={topImg} alt="top" />
                    </div> : null}

                <div className={this.state.activeMobile ? "overflowMenu" : null}>
                    <nav className={this.setClassName().join(" ")}>
                        {/* {this.state.width <= 781 ? <div onClick={this.handleMobileMenu} className={this.state.activeMobile ? "hamburger show" : "hamburger"}></div> : null} */}

                        <div className="container">
                            <div className="containerNav">

                                <div className="navPart">
                                    <ul>
                                        <li onClick={() => this.handleMobileMenu(false)}><Link to="/" activeClassName="active"  > Strona główna</Link></li>
                                        <li onClick={() => this.handleMobileMenu(false)}><Link to="/o-firmie/" activeClassName="active" > O firmie </Link> </li>
                                        <li>
                                            <Link
                                                className={regexOffer.test(this.props.location.pathname) ? "active" : null}
                                                to={regexOffer.test(this.props.location.pathname) ? this.props.location.pathname : "/oferta/tapicerstwo"} activeClassName="active" >
                                                Oferta
                                    </Link>

                                            {this.state.width <= 781 ?
                                                <>
                                                    {regexOffer.test(this.props.location.pathname) && this.state.offer ?
                                                        <ul className="submenu"  >
                                                            <li onClick={() => this.handleMobileMenu(false)}><Link to="/oferta/kusnierstwo" activeClassName="active" > Kuśnierstwo</Link></li>
                                                            <li onClick={() => this.handleMobileMenu(false)}><Link to="/oferta/krawiectwo" activeClassName="active" > Krawiectwo</Link></li>
                                                            <li onClick={() => this.handleMobileMenu(false)}><Link to="/oferta/tapicerstwo" activeClassName="active" > Tapicerstwo</Link></li>
                                                            <li onClick={() => this.handleMobileMenu(false)}><Link to="/oferta/rymarstwo" activeClassName="active" > Rymarstwo</Link></li>
                                                            <li onClick={() => this.handleMobileMenu(false)}><Link to="/oferta/kaletnictwo" activeClassName="active" > Kalecnictwo</Link></li>

                                                        </ul> : null
                                                    }
                                                </> : null}
                                        </li>
                                    </ul>
                                </div>
                                <div className="logo"><Link state={{ choice: 'index' }} to="/"  >
                                    {this.props.location.pathname === "/" ? <img src={this.state.scrollPosition > 50 ? logoScroll : logo} alt="logo" /> :
                                        <img style={{ width: "50px" }} src={logoScroll} alt="logo" />

                                    }

                                </Link></div>
                                <div className="navPart">
                                    <ul>
                                        <li>
                                            <Link
                                                to={regexGallery.test(this.props.location.pathname) ? this.props.location.pathname : "/galeria/tapicerstwo"}
                                                className={regexGallery.test(this.props.location.pathname) ? "active" : null}
                                                activeClassName="active" > Galeria</Link>
                                            {this.state.width <= 781 ?
                                                <>
                                                    {regexGallery.test(this.props.location.pathname) && this.state.offer ?
                                                        <ul className="submenu"  >
                                                            <li onClick={() => this.handleMobileMenu(false)}><Link to="/galeria/kusnierstwo" activeClassName="active" > Kusnierstwo</Link></li>
                                                            <li onClick={() => this.handleMobileMenu(false)}><Link to="/galeria/tapicerstwo" activeClassName="active" > tapicerstwo</Link></li>
                                                            <li onClick={() => this.handleMobileMenu(false)}><Link to="/galeria/pozostale" activeClassName="active" > Pozostałe</Link></li>

                                                        </ul> : null
                                                    }
                                                </> : null}

                                        </li>
                                        <li>
                                            <Link
                                                className={regexPrice.test(this.props.location.pathname) ? "active" : null}
                                                to={regexPrice.test(this.props.location.pathname) ? this.props.location.pathname : "/cennik/kusnierstwo"}
                                                activeClassName="active" >
                                                Cennik
                                    </Link>
                                            {this.state.width <= 781 ?
                                                <>
                                                    {regexPrice.test(this.props.location.pathname) && this.state.offer ?
                                                        <ul className="submenu"  >
                                                            <li onClick={() => this.handleMobileMenu(false)}><Link to="/cennik/kusnierstwo" activeClassName="active" > Kuśnierstwo</Link></li>
                                                            <li onClick={() => this.handleMobileMenu(false)}><Link to="/cennik/tapicerstwo-medyczne" activeClassName="active" > Tapicerstwo medyczne</Link></li>
                                                            <li onClick={() => this.handleMobileMenu(false)}><Link to="/cennik/tapicerstwo-samochodowe" activeClassName="active" > Tapicerstwo samochodowe</Link></li>
                                                            <li onClick={() => this.handleMobileMenu(false)}><Link to="/cennik/tapicerstwo-meblowe" activeClassName="active" > Tapicerstwo mblowe</Link></li>

                                                        </ul> : null
                                                    }
                                                </> : null}
                                        </li>
                                        <li onClick={() => this.handleMobileMenu(false)}><Link to="/kontakt" activeClassName="active" > Kontakt</Link></li>
                                    </ul>
                                </div>
                            </div>


                            {this.state.width > 781 ?
                                <>
                                    {regexPrice.test(this.props.location.pathname) && this.state.offer ?
                                        <ul className={regexPrice.test(this.props.location.pathname) ? "submenu submenuPrice" : "submenu"}  >
                                            <li><Link className={regexKus.test(this.props.location.pathname) ? "active" : null} onClick={() => this.handleMobileMenu(false)} to="/cennik/kusnierstwo" activeClassName="active" > Kuśnierstwo</Link></li>
                                            <li><Link className={regexTapMed.test(this.props.location.pathname) ? "active" : null} to="/cennik/tapicerstwo-medyczne" activeClassName="active" > Tapicerstwo medyczne</Link></li>
                                            <li><Link className={regexTapSam.test(this.props.location.pathname) ? "active" : null} to="/cennik/tapicerstwo-samochodowe" activeClassName="active" > Tapicerstwo samochodowe</Link></li>
                                            <li><Link className={regexTapMeb.test(this.props.location.pathname) ? "active" : null} to="/cennik/tapicerstwo-meblowe" activeClassName="active" > Tapicerstwo mblowe</Link></li>

                                        </ul> : null
                                    }

                                </> : null}
                            {this.state.width > 781 ?
                                <>
                                    {regexGallery.test(this.props.location.pathname) && this.state.offer ?
                                        <ul className="submenu"  >
                                            <li><Link className={regexKus.test(this.props.location.pathname) ? "active" : null} to="/galeria/kusnierstwo" activeClassName="active" > Kuśnierstwo</Link></li>
                                            <li><Link className={regexTap.test(this.props.location.pathname) ? "active" : null} to="/galeria/tapicerstwo" activeClassName="active" > Tapicerstwo</Link></li>
                                            <li><Link className={regexOther.test(this.props.location.pathname) ? "active" : null} to="/galeria/pozostale" activeClassName="active" > Pozostałe</Link></li>

                                        </ul> : null
                                    }

                                </> : null}
                            {this.state.width > 781 ?
                                <>
                                    {regexOffer.test(this.props.location.pathname) && this.state.offer ?
                                        <ul className="submenu"  >
                                            <li><Link className={regexKus.test(this.props.location.pathname) ? "active" : null} to="/oferta/kusnierstwo" activeClassName="active" > Kuśnierstwo</Link></li>
                                            <li><Link className={regexKraw.test(this.props.location.pathname) ? "active" : null} to="/oferta/krawiectwo" activeClassName="active" > Krawiectwo</Link></li>
                                            <li><Link className={regexTap.test(this.props.location.pathname) ? "active" : null} to="/oferta/tapicerstwo" activeClassName="active" > Tapicerstwo</Link></li>
                                            <li><Link className={regexRym.test(this.props.location.pathname) ? "active" : null} to="/oferta/rymarstwo" activeClassName="active" > Rymarstwo</Link></li>
                                            <li><Link className={regexKal.test(this.props.location.pathname) ? "active" : null} to="/oferta/kaletnictwo" activeClassName="active" > Kalecnictwo</Link></li>

                                        </ul> : null
                                    }

                                </> : null}
                        </div>

                    </nav >
                </div>
            </>
        )
    }

}



export default Navigation;