import React, { useState } from "react"
import axios from "axios";
import "../styles/form.scss"



const MyForm = () => {

    const [serverState, setServerState] = useState({
        submitting: false,
        status: null,
        username: ''
    });
    const [username, setUsername] = useState('');
    const [usermail, setUsermail] = useState('');
    const [usermessage, setUsermessage] = useState('');
    const [checkPolicy, setCheckPolicy] = useState('');

    const handleServerResponse = (ok, msg, form) => {
        setServerState({
            submitting: false,
            status: { ok, msg }
        });
        if (ok) {
            form.reset();
        }
    };

    const handleOnSubmit = e => {
        e.preventDefault();
        const form = e.target;
        setServerState({ submitting: true });
        axios({
            method: "post",
            url: "https://formspree.io/f/xjvpkvwr",
            data: new FormData(form)
        })
            .then(r => {
                handleServerResponse(true, "Dziękujemy, Twoja wiadomość została wysłana!", form);
            })
            .catch(r => {
                handleServerResponse(false, r.response.data.error, form);
            });
    };

    return (



        <div className="form">
            <form onSubmit={handleOnSubmit}>
                <div className="formItem">
                    <label htmlFor="inputName" required="required">Imię</label>
                    <input onChange={event => setUsername(event.target.value)} type="text" name="name" id="inputName" placeholder="Twoje imię" />
                </div>
                <div className="formItem">
                    <label htmlFor="inputEmail">Adres email</label>
                    <input onChange={event => setUsermail(event.target.value)} type="text" name="email" id="inputEmail" placeholder="Twój adres e-mail" required="required" />
                </div>
                <div className="formItem formItem--textarea">
                    <label htmlFor="inputMessage">Wiadomość</label>
                    <textarea onChange={event => setUsermessage(event.target.value)} type="text" name="message" id="inputMessage" placeholder="Twoja wiadomość" required="required" />
                </div>
                <div className="formItem formItem--checkbox">
                    <input onClick={event => setCheckPolicy(event.target.checked)} type="checkbox" name="policy" id="check" required="required" />
                    <label htmlFor="check">Wysyłając wiadomość zgadzasz się na warunki opisane na stronie<a href="/polityka_prywatnosci.pdf">Polityka Prywatności Cookies</a> </label>

                </div>

                <button
                    type="submit"
                    className={username && usermail && usermessage && checkPolicy ? "formButton " : "formButton valid"}
                    disabled={serverState.submitting}>
                    Wyślij
                </button>
                {serverState.status && (
                    <p className={!serverState.status.ok ? "errorMsg" : ""}>
                        {serverState.status.msg}
                    </p>
                )}
            </form>
        </div>



    );
};

export default MyForm;