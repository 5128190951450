import React from "react"
import Navigation from "../components/Navigation"
import "../styles/main.scss"
import Contact from "../components/Contact"
import Footer from "../components/Footer"
import CookieConsent from "react-cookie-consent";


const MainLayout = (props) => (

    <>
        <Navigation
            location={props.location}
        />


        {props.location.pathname === "/kontakt" ?
            <>
                <Contact />
                {props.children}
            </>
            :
            <>
                {props.children}
                <Contact />
            </>
        }
        <CookieConsent
            location="bottom"
            buttonText="Rozumiem"
            cookieName="myAwesomeCookieName2"
            buttonClasses="confirmCookies cookieButton"
            contentClasses="informationContainer"
            containerClasses="cookieContainer"
            disableStyles
        >
            <span className="cookieText"> Strona kusnierz.konin.pl wykorzystuje cookies, by działać jak najsprawniej. Kontynuując przeglądanie wyrażasz zgodę na korzystanie przez nas z cookies.</span>
            <button className="cookieButton">
                <a className="cookieLink" href="/polityka_prywatnosci.pdf">Polityka Prywatności</a>

            </button>
        </CookieConsent>
        <Footer />

    </>


)

export default MainLayout
