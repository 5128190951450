import React from 'react';
import "../styles/footer.scss"
import { Link } from "gatsby"



const Footer = () => (

    <footer className="footer">
        <div className="container">
            <div className="footerLinkContainer">
                <div className="footerLink">
                    <Link to="/oferta/kusnierstwo" activeClassName="active"  > Kuśnierstwo</Link>
                </div>
                <div className="footerLink">
                    <Link to="/oferta/krawiectwo" activeClassName="active"  > Krawiectwo</Link>
                </div>
                <div className="footerLink">
                    <Link to="/oferta/tapicerstwo" activeClassName="active"  > Tapicerstwo</Link>
                </div>
                <div className="footerLink">
                    <Link to="/oferta/rymarstwo" activeClassName="active"  > Rymarstwo</Link>
                </div>
                <div className="footerLink">
                    <Link to="/oferta/kaletnictwo" activeClassName="active"  > Kaletnictwo</Link>
                </div>

            </div>
            <div className="footerLink footerLink--policy">
                <a href="/polityka_prywatnosci.pdf">Polityka Prywatności</a>
            </div>
        </div>

    </footer>



)

export default Footer;