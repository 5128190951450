import React from "react"
import Form from "./Form"
import "../styles/contact.scss"
import pin from "../images/main/pin.svg"
import clock from "../images/main/clock.svg"
import phone from "../images/main/phone.svg"
import mail from "../images/main/mail.svg"

const Contact = () => (
  <address className="contact container">
    <div className="contactInformation">
      <h3>KONTAKT</h3>
      <p className="desc">
        Realizujemy indywidualne i nietypowe zamówienia oraz projekty klienta.
        Jeśli masz jakiekolwiek pytania skontaktuj się z nami telefonicznie lub
        wypełnij formularz.
      </p>
      <div className="contacItem">
        <img src={pin} alt="pin" />
        <div className="inf">
          <p className="title">Adres firmy</p>
          <p className="name">
            <a
              href="https://www.google.com/maps/place/Ku%C5%9Bnierz+-+Tapicer/@52.2011957,18.237557,15z/data=!4m2!3m1!1s0x0:0x24f07d52a27b93c8?sa=X&ved=2ahUKEwjfqrKmr7ruAhXHAWMBHV6IDVoQ_BIwFHoECCAQBQ"
              target="_blank"
              rel="noreferrer"
            >
              ul. Jana Zemełki 76, 62-500 Konin
            </a>
          </p>
        </div>
      </div>
      <div className="contacItem">
        <img src={clock} alt="zegar" />
        <div className="inf">
          <p className="title">Godziny otwarcia</p>
          <p className="name">
            W każdy wtorek i środę: <br />
            <b>9:00 - 14:00 </b>oraz <b>16:15 - 18:00</b> <br />
            Pozostałe dni po wcześniejszym uzgodnieniu
          </p>
        </div>
      </div>
      <div className="contacItem">
        <img src={phone} alt="telefon" />
        <div className="inf">
          <p className="title">Zadzwoń do nas</p>
          <p className="name">
            <a href="tel:+48608455353">+48 608 455 353</a>
          </p>
        </div>
      </div>
      <div className="contacItem">
        <img src={mail} alt="mail" />
        <div className="inf">
          <p className="title">Napisz do nas</p>
          <p className="name">
            <a href="mailto:marekbakalarski@onet.eu">marekbakalarski@onet.eu</a>{" "}
          </p>
        </div>
      </div>
    </div>

    <div className="formContainer">
      <Form />
    </div>
  </address>
)

export default Contact
